import * as React from "react"

import Layout from "../components/common/layout/Layout"
import SubscribeForm from "../components/sections/SubscribeForm"
import ContactForm from "../components/sections/ContactForm"
import Seo from "../components/common/SEO"

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Contactez-nous" description="Vers le mari de ma destinée" />
      <ContactForm />
      <SubscribeForm bgLight />
    </Layout>
  )
}

export default ContactPage
