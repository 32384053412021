import React from "react"
import styled from "styled-components"
import Botpoison from "@botpoison/browser"

import { Container, Section } from "../common/layout/Containers"
import { Subtitle } from "./sectionsStyles"
import ContactForm from "../forms/Contact"

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
}

const botpoison = new Botpoison({
  publicKey: process.env.GATSBY_BOT_POISON_KEY,
})

export default class Contact extends React.Component {
  state = {
    formValues: { name: "", object: "", email: "", message: "" },
    sendStatus: { success: null },
    submitting: false,
    errorEmail: false,
  }

  handleSubmit = async (e) => {
    let self = this
    e.preventDefault()
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
        this.state.formValues.email
      )
    ) {
      self.setState((prevState) => ({
        errorEmail: true,
      }))
    } else {
      self.setState((prevState) => ({
        submitting: true,
      }))
      const { solution } = await botpoison.challenge()
      fetch(process.env.GATSBY_FORM_SPARK_BOOK_CONTACT, {
        method: "POST",
        headers,
        body: JSON.stringify({
          name: this.state.formValues.name,
          email: this.state.formValues.email,
          object: this.state.formValues.object,
          message: this.state.formValues.message,
          _botpoison: solution,
        }),
      })
        .then(function (response) {
          self.setState((prevState) => ({
            submitting: false,
          }))
          self.resetForm()
          self.setState((prevState) => ({
            sendStatus: {
              ...prevState.sendStatus,
              success: true,
            },
          }))
        })
        .catch(function (error) {
          self.setState((prevState) => ({
            sendStatus: {
              ...prevState.sendStatus,
              success: false,
            },
          }))
        })
    }
  }

  resetForm = () => {
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        name: "",
        email: "",
        object: "",
        message: "",
      },
    }))
  }

  handleInputChange = (event) => {
    if (event.target.name === "email" && this.state.errorEmail) {
      console.log("erreur email")
      this.setState((prevState) => ({
        errorEmail: false,
      }))
    }
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        [event.target.name]: event.target.value,
      },
    }))
  }

  render() {
    let { success } = this.state.sendStatus
    let responseMessage = {
      title: "Nous avons reçu votre message",
      subtitle: "Nous vous contacterons sous peu.",
      background: "#d4edda",
      color: "#155724",
    }

    if (success === false) {
      responseMessage.title = "Une erreur s'est produite :("
      responseMessage.subtitle = "Veuillez réessayer svp."
      responseMessage.background = "#f8d7da"
      responseMessage.color = "#721c24"
    }

    return (
      <StyledSection>
        <GetStartedContainer>
          <GetStartedTitle style={{ marginBottom: "0" }}>
            Nous contacter
          </GetStartedTitle>
          {success !== null && (
            <Subtitle
              style={{
                backgroundColor: `${responseMessage.background}`,
                color: `${responseMessage.color}`,
                padding: " .75rem 1.25rem",
                border: "1px solid transparent",
                borderRadius: ".25rem",
                textAlign: "center",
                marginLeft: "1rem",
                marginRight: "1rem",
                marginBottom: "0",
                marginTop: "2rem",
              }}
            >
              <span style={{ display: "block", fontFamily: "HK Grotesk Bold" }}>
                {responseMessage.title}
              </span>
              {responseMessage.subtitle}
            </Subtitle>
          )}
          <ContactForm
            buttonName="Envoyer"
            handleSubmit={this.handleSubmit}
            handleInputChange={this.handleInputChange}
            name={this.state.formValues.name}
            email={this.state.formValues.email}
            object={this.state.formValues.object}
            message={this.state.formValues.message}
            disableButton={this.state.submitting}
            errorEmail={this.state.errorEmail}
          />
        </GetStartedContainer>
      </StyledSection>
    )
  }
}

const StyledSection = styled(Section)`
  //   background-color: ${(props) => props.theme.color.background.light};
  //   clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`
