import React from "react"
import styled from "styled-components"

import { Form, Input, TextArea } from "./formsStyles"
import { TryItButton } from "../sections/sectionsStyles"

const Contact = ({
  buttonName,
  handleSubmit,
  handleInputChange,
  name,
  email,
  object,
  message,
  disableButton,
  errorEmail,
}) => {
  return (
    <FormStyled onSubmit={handleSubmit}>
      <input type="text" name="_gotcha" style={{ display: "none" }} />
      <InputStyled
        placeholder="Nom"
        type="text"
        name="name"
        required
        value={name}
        onChange={handleInputChange}
      />
      {errorEmail && <small style={{ color: "red" }}>Email invalide</small>}
      <InputStyled
        placeholder="Courriel"
        type="email"
        name="email"
        required
        value={email}
        onChange={handleInputChange}
      />
      <InputStyled
        placeholder="Objet du message"
        type="text"
        name="object"
        required
        value={object}
        onChange={handleInputChange}
      />
      <TextArea
        placeholder="Message"
        name="message"
        required
        value={message}
        onChange={handleInputChange}
        rows="5"
      />
      <TryItButton
        type="submit"
        style={{ marginLeft: "0" }}
        disabled={disableButton}
      >
        {buttonName}
      </TryItButton>
    </FormStyled>
  )
}

export default Contact

const InputStyled = styled(Input)`
  display: block;
  width: 100%;
  margin-bottom: 1rem;
`

const FormStyled = styled(Form)`
  margin: 4rem 0;
  flex-direction: column;
  width: 60%;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 90%;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 90%;
  }
`
